<template>
  <div>
    <!-- 导航  -->
    <div class="AVR-menu" v-if="urlIsStr === 'YES'? true:false">
      <div class=" AVR-menu-logo">
        <a href="#" class="AVR-logo"><img src="../assets/images/logo.png" alt="logo" /></a>
        <el-menu mode="horizontal" @select="handleSelect" router>
          <el-menu-item index="/home" :class="activeIndex === '/home'? 'el-menu-item is-active': 'el-menu-item'">
            首页
          </el-menu-item>
          <el-menu-item index="/news" :class="activeIndex === '/news'? 'el-menu-item is-active': 'el-menu-item'">
            新闻中心
          </el-menu-item>
          <el-menu-item index="/theme" :class="activeIndex === '/theme'? 'el-menu-item is-active': 'el-menu-item'">
            我要建展
          </el-menu-item>
          <el-menu-item index="/library" :class="activeIndex === '/library'? 'el-menu-item is-active': 'el-menu-item'">
            素材库
          </el-menu-item>
          <el-menu-item index="/order" :class="activeIndex === '/order'? 'el-menu-item is-active': 'el-menu-item'">
            订单管理
          </el-menu-item>
        </el-menu>
        <div class="AVR-tel">{{navigation.phonenumber}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderView',
  inject: ['reload'],
  data() {
    return {
      activeIndex: '/home',
      active: 'display:none',
      iStokenStr: false,
      urlIsStr: 'NO',
      // 基本信息
      navigation: {
        announcement: '', // 网站公告
        id: '', // id
        phonenumber: '', // 手机
        qq: '', // qq
        filing: '', // 备案
        email: '', // email
        wx: '', // 微信
        support: '', // 技术支持
        qrcode: '' // 二维码
      }
    }
  },
  created() {
    const addressUrl = window.sessionStorage.getItem('addressUrl')
    if (addressUrl === null) {
      this.activeIndex = '/home'
    } else {
      this.activeIndex = addressUrl
    }
    const urlIs = window.sessionStorage.getItem('urlIs')
    this.urlIsStr = urlIs
    const tokenStr = window.sessionStorage.getItem('token')
    if (tokenStr) {
      this.iStokenStr = true
    } else {
      this.iStokenStr = false
    }
    this.navigationInfo() // 获取基本信息
  },
  methods: {
    // 获取基本信息
    async navigationInfo() {
      const { data: res } = await this.$http.get('/navigation')
      this.imageUrl = res.data.qrcode
      this.navigation = res.data
      this.reload()
    },
    // 退出登录
    quitLogin() {
      window.sessionStorage.clear()
      this.$router.push('/userlogin')
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
      window.sessionStorage.setItem('addressUrl', key)
    },
    mouseOver() {
      this.active = 'display:block'
    },
    mouseLeave() {
      this.active = 'display:none'
    }
  }
}
</script>
<style lang="less" scoped>
// 头部导航
.AVR-menu {
  width: 100%;
  height: 68px;
  background-color: #1a0035;
  margin-top: 32px;
  .AVR-menu-logo {
    position: relative;
    width: 1300px;
    height: 68px;
    margin: 0 auto;
    .AVR-logo {
      position: absolute;
      top: 18px;
      left: 0;
      width: 167px;
      height: 34px;
      display: block;
    }
    /deep/ .el-menu {
      position: absolute;
      margin: 0px;
      width: 700px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 70px;
      // overflow: hidden;
      background: none;
      border: none;
      .el-menu-item {
        text-decoration: none;
        display: block;
        margin: 0px;
        margin: 0px 20px;
        padding: 0px 20px;
        height: 68px;
        float: left;
        font-size: 16px;
        line-height: 68px;
        color: #ffffff;
        &:hover {
          height: 68px;
          border: none;
          background: url(../assets/images/menuHbg.png) no-repeat center bottom #311a4a;
        }
        // a {
        //   text-decoration: none;
        //   display: block;
        //   margin: 0px;
        //   padding: 0px 20px;
        //   height: 68px;
        //   float: left;
        //   font-size: 16px;
        //   line-height: 68px;
        //   color: #ffffff;
        //   &:hover {
        //     height: 68px;
        //     border: none;
        //     background: url(../assets/images/menuHbg.png) no-repeat center bottom #311a4a;
        //   }
        // }
      }
      .is-active {
        height: 68px;
        border: none;
        background: url(../assets/images/menuHbg.png) no-repeat center bottom #311a4a;
      }
    }
    .AVR-tel {
      position: absolute;
      top: 20px;
      right: 0;
      width: 135px;
      height: 24px;
      background: url(../assets/images/tel.png) no-repeat left center;
      vertical-align: middle;
      text-indent: 30px;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>
