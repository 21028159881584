import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
// import uuid from 'uuid'
import './plugins/element.js'
import { Message, Notification } from 'element-ui'
import './assets/css/global.css'
import 'element-ui/lib/theme-chalk/display.css'
// 引入富文本
import VueQuillEditor from 'vue-quill-editor'
// 引入iconMoon字体图标
import './assets/icomoon/style.css'
import './assets/fonts/iconfont.css'
// 引入swiper组件
import vueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// 引用UUID
// Vue.use(uuid)

Vue.use(VueQuillEditor /* {default global option} */)

Vue.use(vueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.$http = axios

// 登录超时设置-------------------------开始
axios.interceptors.response.use(function (response) { // ①10010 token过期(30天) ②10011 token无效
  if (response.data.code === 401) {
    // const adminPath = ['/admin', '/welcome', '/ordergroup', '/exhibition', '/newslist', '/addinfo', '/template', '/userlist', '/basic', '/addtemp', '/edittemp', '/addexhi', '/editexhi']
    const loginUrl = window.sessionStorage.getItem('loginUrl')
    if (['/admin', '/welcome', '/ordergroup', '/exhibition', '/newslist', '/addinfo', '/template', '/userlist', '/basic', '/addtemp', '/edittemp', '/addexhi', '/editexhi'].includes(loginUrl)) {
      window.sessionStorage.removeItem('token')
      router.replace({
        path: '/login' // 到登录页重新获取token
      })
      // location.reload()// 为了重新实例化vue-router对象
      Notification({
        title: '警告',
        message: '登录状态已过期，请重新登录!',
        type: 'warning',
        showClose: true
      })
      return
    } else {
      window.sessionStorage.removeItem('token')
      router.replace({
        path: '/userlogin' // 到登录页重新获取token
      })
      Notification({
        title: '警告',
        message: '登录状态已过期，请重新登录!',
        type: 'warning',
        showClose: true
      })
    }
  } else if (response.data.token) { // 判断token是否存在，如果存在说明需要更新token
    window.sessionStorage.setItem('token', response.data.token) // 覆盖原来的token(默认一天刷新一次)
  }
  return response
}
  // , error => {
  //   Message.error('登录超时！！')
  //   return Message.error(error)
  // }
)
// 登录超时设置-------------------------结束
switch (process.env.NODE_ENV) {
  case 'production':
    axios.defaults.baseURL = 'https://yzavr.com/api/'
    // axios.defaults.baseURL = 'http://avr.vrstarrysky.cn:9932/'
    break
  case 'text':
    axios.defaults.baseURL = 'http://10.0.0.115:8099'
    break
  default:
    axios.defaults.baseURL = 'http://10.0.0.115:8099'
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
