import Vue from 'vue'
import {
  Button, Header, Footer, Dialog, Breadcrumb, Menu, MenuItem, Submenu, BreadcrumbItem, Input, Form, FormItem, Pagination, Tabs, Col, Card, Row, Upload, TabPane, MessageBox,
  Message, Image, Container, Progress, Icon, Checkbox, CheckboxButton, CheckboxGroup, ColorPicker, Carousel, CarouselItem, Radio, RadioGroup, RadioButton, Aside, Main, Table, TableColumn, TimeSelect, InputNumber, TimePicker, Backtop, DatePicker, Loading, Tag, Slider
} from 'element-ui'
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.use(InputNumber)
Vue.use(Backtop)
Vue.use(Button)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Container)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Image)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Upload)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Dialog)
Vue.use(Progress)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(DatePicker)
Vue.use(Tag)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
