<template>
  <div id="app">
    <Header v-show="$route.meta.show"></Header>
    <router-view v-if="isRouterAlive"></router-view>
    <el-backtop target="#app" :visibility-height="100">
      <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        border-radius: 10px;
        color: #1989fa;
      }">
        UP
      </div>
    </el-backtop>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      urlIsStr: '',
      isRouterAlive: true
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  },
  components: {
    Header
  }
}
</script>
<style lang="less" scoped>
#app {
  overflow-y: scroll;
}
.backtop {
  overflow: scroll;
  height: 100%;
}
</style>
