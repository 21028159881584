import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.NODE_ENV === 'production' ? '/yzk' : './',
  // base: (process.env.NODE_ENV === 'production') || (process.env.NODE_ENV === 'text') ? '/yzk' : './',
  routes: [
    // 前台页面
    { path: '/', redirect: '/home' },
    {
      path: '/ceshi',
      name: 'ceshi',
      component: () => import('@/views/Ceshi.vue'),
      meta: { title: '测试用', show: true }
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: { title: '主网首页', show: true }
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/News.vue'),
      meta: { title: '新闻页', show: true }
    },
    {
      path: '/newsShow',
      name: 'newsShow',
      component: () => import('@/views/NewsShow.vue'),
      meta: { title: '新闻列表页', show: true }
    },
    {
      path: '/share',
      name: 'share',
      component: () => import('@/views/Share.vue'),
      meta: { title: '分享', show: true }
    },
    // 前台用户界面--
    {
      path: '/userlogin',
      name: 'userlogin',
      component: () => import('@/views/UserAdmin/Userlogin.vue'),
      meta: { title: '登录', show: true }
    },
    {
      path: '/reg',
      name: 'reg',
      component: () => import('@/views/UserAdmin/Register.vue'),
      meta: { title: '注册', show: true }
    },
    {
      path: '/editpass',
      name: 'editpass',
      component: () => import('@/views/UserAdmin/EditPass.vue'),
      meta: { title: '修改登录密码', show: true }
    },
    {
      path: '/theme',
      name: 'theme',
      component: () => import('@/views/UserAdmin/Theme.vue'),
      meta: { title: '主题', show: true }
    },
    {
      path: '/create',
      name: 'create',
      component: () => import('@/views/UserAdmin/Create.vue'),
      meta: { title: '我要建展', show: true }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/UserAdmin/Order.vue'),
      meta: { title: '订单管理', show: true }
    },
    {
      path: '/userdata',
      name: 'userdata',
      component: () => import('@/views/UserAdmin/UserData.vue'),
      meta: { title: '个人资料', show: true }
    },
    {
      path: '/library',
      name: 'library',
      component: () => import('@/views/UserAdmin/Library.vue'),
      meta: { title: '素材库', show: true }
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: () => import('@/views/UserAdmin/Recovery.vue'),
      meta: { title: '回收站', show: true }
    },
    {
      path: '/editmodel',
      name: 'editmodel',
      component: () => import('@/views/UserAdmin/EditModel.vue'),
      meta: { title: '修改手机号', show: true }
    },
    // 后台管理--
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/Admin/Login.vue'),
      meta: { title: '后台管理', show: false }
    }, // -登录
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/components/Admin/Admin.vue'),
      meta: { title: '后台主页', show: false },
      redirect: '/welcome',
      children: [
        {
          path: '/welcome',
          name: 'welcome',
          component: () => import('@/components/Admin/Welcome.vue'),
          meta: { title: '欢迎页', show: false }
        },
        {
          path: '/ordergroup',
          name: 'ordergroup',
          component: () => import('@/components/Admin/OrderGroup.vue'),
          meta: { title: '订单', show: false }
        },
        {
          path: '/exhibition',
          name: 'exhibition',
          component: () => import('@/components/Admin/Exhibition.vue'),
          meta: { title: '主题展厅管理', show: false }
        },
        {
          path: '/addexhi',
          name: 'addexhi',
          component: () => import('@/components/Admin/AddExhibition.vue'),
          meta: { title: '新建展厅', show: false }
        },
        {
          path: '/editexhi',
          name: 'editexhi',
          component: () => import('@/components/Admin/EditExhibition.vue'),
          meta: { title: '编辑展厅', show: false }
        },
        {
          path: '/newslist',
          name: 'newslist',
          component: () => import('@/components/Admin/NewsList.vue'),
          meta: { title: '新闻列表', show: false }
        },
        {
          path: '/addinfo',
          name: 'addinfo',
          component: () => import('@/components/Admin/AddInfo.vue'),
          meta: { title: '添加/编辑文章', show: false }
        },
        {
          path: '/template',
          name: 'template',
          component: () => import('@/components/Admin/Template.vue'),
          meta: { title: '模板管理', show: false }
        },
        {
          path: '/addtemp',
          name: 'addtemp',
          component: () => import('@/components/Admin/AddTemplate.vue'),
          meta: { title: '添加模板', show: false }
        },
        {
          path: '/edittemp',
          name: 'edittemp',
          component: () => import('@/components/Admin/EditTemplate.vue'),
          meta: { title: '编辑模板', show: false }
        },
        {
          path: '/userlist',
          name: 'userlist',
          component: () => import('@/components/Admin/UserList.vue'),
          meta: { title: '用户管理', show: false }
        },
        {
          path: '/basic',
          name: 'basic',
          component: () => import('@/components/Admin/Basic.vue'),
          meta: { title: '网站基本信息', show: false }
        }
      ]
    }
  ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // if (to.path === '/admin') return next('/admin')
  // 不拦截的网址
  // const noLoginPath = ['/网站首页', '/新闻列表页', '/新闻内容', '/后台登录', '/前台登录'', '/注册', '/忘记/修改密码', '/修改手机']
  const noLoginPath = ['/ceshi', '/home', '/news', '/newsShow', '/userlogin', '/reg', '/editpass', '/editmodel', '/login', '/share']

  if (noLoginPath.includes(to.path)) {
    if (to.path === '/login') {
      window.sessionStorage.setItem('urlIs', 'NO')
      return next()
    } else {
      window.sessionStorage.setItem('urlIs', 'YES')
      return next()
    }
  }

  // 拦截的网址跳前台userlogo登录页
  // const userPath = ['/我要建展页'，'/订单管理'，'/素材库'，'/素材库'，'/回收站'，'/主题',''/个人资料]
  const userPath = ['/create', '/order', '/userset', '/library', '/recovery', '/theme', '/userdata']
  if (userPath.includes(to.path)) {
    window.sessionStorage.setItem('urlIs', 'YES')
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/userlogin')
    next()
  }

  // 拦截的网址跳后台logo登录页
  // const adminPath = ['/后台首页','/后台欢迎','/后台订单管理','/后台主题管理','/后台新闻列表管理','/添加/编辑新闻'，'/后台模板管理'，'/后台用户列表'，'/后台网站基本信息'，'/后台添加模板'，'/后台编辑模板'，'/后台新建展厅'，'/后台编辑展厅']
  const adminPath = ['/admin', '/welcome', '/ordergroup', '/exhibition', '/newslist', '/addinfo', '/template', '/userlist', '/basic', '/addtemp', '/edittemp', '/addexhi', '/editexhi']
  if (adminPath.includes(to.path)) {
    window.sessionStorage.setItem('urlIs', 'NO')
    window.sessionStorage.setItem('loginUrl', to.path)
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/login')
    next()
  }
})

export default router
